import { Heading } from '@troon/ui';
import TroonGolfLandingPages from './_components/layout';
import type { RouteDefinition } from '@solidjs/router';

export default function TroonGolfArizonaLandingPage() {
	return (
		<TroonGolfLandingPages title="Troon Golf Hawaii" image="troon-golf-hawaii.png" slugs={slugs} location="Hawaii">
			<Heading as="h2">Experience the best golf in Hawaii with Troon.</Heading>
			<p>
				Troon Golf courses in Hawaii are as unique as the islands themselves, each woven into the serene terrain with a
				unique vision and infused with the impeccable quality that is a Troon Golf hallmark.
			</p>
		</TroonGolfLandingPages>
	);
}

const slugs = {
	'kaanapali-golf-courses': '808.720.6694',
	kapalua: '808.419.3617',
	'kapolei-golf-club': '808.466.3999',
	'mauna-lani,-auberge-resorts-collection': '808.319.4885',
	'mauna-lani-auberge-resorts-collection': '808.319.4885',
	'ocean-course-at-hokuala': '808.868.5171',
	'pearl-at-kalauao': '808.868.5140',
	'princeville-makai-golf-club': '808.278.6133',
	'the-king-kamehameha-golf-club': '808.249.0033',
	'volcano-golf-course': '808.319.4745',
};

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
