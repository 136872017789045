import { Meta, Title } from '@solidjs/meta';
import {
	Avatar,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	Picture,
	TroonAccess,
	Container,
	HorizontalRule,
	Section,
	Page,
	Button,
	Link,
} from '@troon/ui';
import { For, Index, Show, createEffect, Suspense } from 'solid-js';
import { createAsync, useSearchParams } from '@solidjs/router';
import { twJoin } from '@troon/tailwind-preset/merge';
import { NoHydration } from 'solid-js/web';
import { Icon } from '@troon/icons';
import { useWindowScrollPosition } from '@solid-primitives/scroll';
import { getConfigValue } from '../../modules/config';
import { AccessProducts } from '../../components/access-products';
import { ImageOverlay } from '../../components/image-overlay';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { Grid, GridThird } from '../../components/layouts/grid';
import { FrequentlyAskedQuestions } from '../../components/faqs';
import { FacilitiesByGrouping } from '../../components/facility/by-grouping';
import { ContentCard } from '../../components/card';
import { createContentfulRequest } from '../api/content/[model]/_client';
import { createIsExperimentBranch } from '../../providers/faux-experiment';
import type { RouteDefinition } from '@solidjs/router';
import type { ParentProps } from 'solid-js';

export default function AccessLanding() {
	const [searchParams] = useSearchParams();

	const data = createAsync(async () => getData({}), { deferStream: true });
	const benefitsData = createAsync(async () => getBenefits('troon-access-benefits-public'), { deferStream: true });

	const scroll = useWindowScrollPosition();

	const showGolfpass = createIsExperimentBranch('golfpass+', 'show golfpass+');

	let pic: HTMLImageElement;
	createEffect(() => {
		pic!.style.transform = `scale(${Math.max(scroll.y / window.innerWidth + 1, 1)})`;
	});

	return (
		<>
			<Title>Troon Access | Exclusive savings at more than 150 participating golf courses | Troon</Title>
			<Meta
				name="description"
				content="The ultimate way to experience Troon golf. Exclusive access to savings at more than 150 participating Troon golf courses."
			/>

			<div class="relative flex min-h-64 flex-col items-center bg-white pt-12 sm:min-h-72 md:min-h-96">
				<div
					// eslint-disable-next-line tailwindcss/no-arbitrary-value
					class="absolute inset-0 max-h-[560px] overflow-hidden bg-gradient-to-r from-neutral-950 to-brand-700 md:max-h-[800px]"
				>
					<Picture
						ref={pic!}
						src={`${getConfigValue('IMAGE_HOST')}/digital/hero/access.jpg`}
						alt=""
						sizes={[
							[480, 500],
							[960, 640],
							[1280, 819],
						]}
						// eslint-disable-next-line tailwindcss/no-arbitrary-value
						class="size-full bg-center object-cover brightness-[.65]"
						loading="eager"
						draggable={false}
					/>
					<ImageOverlay class="absolute -bottom-1" />
				</div>
				<div class="relative z-20 overflow-visible">
					<div class="-mb-10 flex min-h-96 flex-col items-center justify-center gap-4 px-2 text-white sm:px-0">
						<h1 class="max-w-xl text-center text-3xl font-semibold md:text-5xl">
							<TroonAccess class="w-full min-w-64 max-w-96" />
							<span class="sr-only">Troon Access</span>
						</h1>
						<p class="max-w-xl text-center">
							The ultimate way to experience Troon. Exclusive access to savings at more than 150 participating Troon
							courses.
						</p>
					</div>
					<Show when={!searchParams.hideOptions}>
						<Container size="small" class="z-30 overflow-visible text-neutral-950">
							<AccessProducts floating />
						</Container>
					</Show>
				</div>
			</div>

			<Page>
				<Section>
					<Container size="small" class="-mb-12">
						<div class="flex flex-wrap items-center justify-between gap-x-4 gap-y-1 rounded bg-brand-100 px-4 pt-4 text-sm text-brand-600 md:py-1">
							<p>The Troon Card has evolved into Troon Access! Learn more about the differences & similarities.</p>
							<Button
								as={Link}
								href="/access/troon-card-vs-troon-access"
								type="submit"
								appearance="transparent"
								size="sm"
								class="relative -start-3 size-fit grow-0 text-sm font-normal normal-case lg:start-0"
							>
								Learn more<span class="sr-only"> about how Troon Access differs from the Troon Card</span>
								<Icon name="arrow-right-md" />
							</Button>
						</div>
					</Container>
					<Container>
						<CenterContent>
							<Heading as="h2">Better benefits, more access</Heading>
							<p>
								Join the all-new Troon Access program to unlock exclusive savings at more than 150 Troon courses
								including Kapalua, Troon North, and Gamble Sands.
							</p>
						</CenterContent>
						<div class="grid w-full grid-cols-3 gap-16">
							<BenefitCallout>
								<Icon name="flag-custom" class="size-16" />
								<Heading as="h3">Save every time you play</Heading>
								<p>
									Save 15% on unlimited rounds. Troon Access+ members enjoy up to 50% off tee times within 78 hours.
								</p>
							</BenefitCallout>
							<BenefitCallout>
								<Icon name="map-custom" class="size-16" />
								<Heading as="h3">Access 150+ world-class courses</Heading>
								<p>Your benefits apply at all participating Troon courses around the U.S.</p>
							</BenefitCallout>
							<BenefitCallout>
								<Icon name="tag-custom" class="size-16" />
								<Heading as="h3">Exclusive Troon Access Perks & Offers</Heading>
								<p>Enjoy additional benefits like partner offers and savings on merchandise.</p>
							</BenefitCallout>
							<Show when={!searchParams.hideOptions}>
								<div class="col-span-3 flex justify-center">
									<Dialog key="access-products">
										<DialogTrigger appearance="primary" class="shrink grow-0">
											Get started <Icon name="arrow-right-md" />
										</DialogTrigger>
										<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
											<AccessProducts />
										</DialogContent>
									</Dialog>
								</div>
							</Show>
						</div>
					</Container>
				</Section>

				<Section appearance="primary" class="relative overflow-hidden">
					<Picture
						alt=""
						src={`https://${getConfigValue('MAP_HOST')}/assets/images/map-base.png`}
						class="absolute inset-x-0 top-0 -z-0 w-full bg-top object-cover"
						loading="lazy"
						sizes={[
							[480, 198],
							[960, 396],
							[1280, 528],
						]}
					/>

					<Container class="relative z-0">
						<For each={data()?.featured?.facilities}>
							{(feat, index) => (
								<Picture
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class={twJoin(
										'absolute -z-0 hidden aspect-[3/4] w-[300px] rounded xl:block',
										index() % 2 ? '-right-6 top-12 -rotate-6' : '-left-12 bottom-12 rotate-6',
									)}
									alt=""
									src={feat.metadata?.hero?.url}
									sizes={[[300, 400]]}
									loading="lazy"
								/>
							)}
						</For>
						<CenterContent>
							<Heading as="h2">150+ World-class courses.</Heading>
							<p>
								Troon Access members can use their benefits across the entire network of participating Troon courses.
								Search courses by state below:
							</p>
						</CenterContent>

						<FacilitiesByGrouping facilities={data()?.facilities.facilities} inverse />
					</Container>
					<ImageOverlay class="absolute -bottom-1" />
				</Section>

				<Show when={showGolfpass}>
					<>
						<Container>
							<div class="grid grid-cols-1 gap-x-16 gap-y-8 md:grid-cols-2 lg:gap-x-36">
								<div class="flex flex-col justify-center gap-8">
									<Section>
										<Picture src={golfpassLogo} alt="GolfPass+" sizes={[[455, 114]]} width={227} height={57} />
										<Heading as="h2">Unlock a Year of Free GolfPass+ with Troon Access+</Heading>
										<p>
											New members get access to stream live sporting events on Peacock, plus news, exclusive shows, hit
											movies, and more! Additional GolfPass+ benefits include waived convenience fees, tee time
											protection and $10 monthly anytime tee time credits - that's $120 a year to go play golf any time,
											any day you want. Plus, all the great benefits of GolfPass Video such as on-demand lessons, access
											to all originals like The Conor Moore Show and Ask Rory.
										</p>

										<div>
											<Dialog key="access-products-golfpass">
												<DialogTrigger appearance="primary">
													Get started <Icon name="arrow-right-md" />
												</DialogTrigger>
												<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
													<AccessProducts />
												</DialogContent>
											</Dialog>
										</div>
									</Section>
								</div>
								<div class="flex flex-col justify-center gap-8">
									<Section class="rounded bg-brand-100 p-4 md:p-6">
										<Heading as="h3" size="p" class="uppercase">
											Included with GolfPass+
										</Heading>
										<ul class="flex flex-col gap-2">
											<li class="flex flex-row items-baseline gap-2">
												<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />
												Waived Convenience Fees for 10 tee time bookings (up to 4 players per booking)
											</li>
											<li class="flex flex-row items-baseline gap-2">
												<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />
												$120 in monthly Anytime Tee Time Credits (12 at $10 each)
											</li>
											<li class="flex flex-row items-baseline gap-2">
												<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />
												Tee time protection on up to 10 tee time bookings (up to 1 hour prior to tee time)
											</li>
											<li class="flex flex-row items-baseline gap-2">
												<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />1 year of Peacock
												included ($79.99 value)
											</li>
											<li class="flex flex-row items-baseline gap-2">
												<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />
												Redeem GolfPass Points on all qualifying rounds
											</li>
											<li class="flex flex-row items-baseline gap-2">
												<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />
												$40 TaylorMade Store Credit
											</li>
										</ul>
									</Section>
								</div>
							</div>
						</Container>
						<Container>
							<HorizontalRule />
						</Container>
					</>
				</Show>

				<NoHydration>
					<Container>
						<Section>
							<div class="mx-auto flex max-w-3xl flex-col items-center gap-4 text-center">
								<Heading as="h2">Additional Member Benefits</Heading>
								<p>
									Join the all-new Troon Access program to unlock exclusive savings at more than 150 Troon courses
									including Kapalua, Troon North, and Gamble Sands.
								</p>
							</div>
							<div class="mb-12 grid grid-cols-2 gap-4 md:gap-8 lg:grid-cols-3">
								<Suspense>
									<For each={benefitsData()?.fields.cards}>
										{(benefit) => {
											return <ContentCard {...benefit!.fields} level="h3" size="h4" />;
										}}
									</For>
								</Suspense>
							</div>
						</Section>

						<Show when={!searchParams.hideOptions}>
							<Dialog key="access-products">
								<DialogTrigger appearance="primary" class="mx-auto size-fit shrink grow-0">
									Get started <Icon name="arrow-right-md" />
								</DialogTrigger>
								<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
									<AccessProducts />
								</DialogContent>
							</Dialog>
						</Show>
					</Container>
				</NoHydration>

				<Section>
					<Container>
						<Heading as="h2" size="h2" class="text-center">
							Don’t just take our word for it
						</Heading>
						<Grid>
							<GridThird>
								<Review rating={5}>
									<Quote>
										Booking Troon Access tee times through the new Troon booking experience is as easy as 1, 2, 3. It’s
										fast, easy, and I always know I’m getting the best rates.
									</Quote>
									<Cite location="Birmingham, AL">Jarvis P.</Cite>
								</Review>
							</GridThird>
							<GridThird>
								<Review rating={5}>
									<Quote>
										From top destination courses to my local favorites, having Troon Access across 150 courses has
										helped me play more for less.
									</Quote>
									<Cite location="Scottsdale, AZ">James L.</Cite>
								</Review>
							</GridThird>
							<GridThird>
								<Review rating={5}>
									<Quote>
										As a long-time Troon Cardholder, I love the switch to Troon Access. Instead of watching the clock to
										book at 6pm, I can book as soon as I know I want to play and know I’m getting 15% every time with no
										hassle.
									</Quote>
									<Cite location="Naples, FL">Emily R.</Cite>
								</Review>
							</GridThird>
						</Grid>
					</Container>
				</Section>

				<Container>
					<HorizontalRule />
				</Container>

				<Section>
					<Container size="small">
						<Heading as="h2" size="h2" class="text-center">
							Frequently asked questions
						</Heading>
						<div class="overflow-hidden rounded border border-neutral">
							<FrequentlyAskedQuestions id="troon-access-faqs" />
						</div>
					</Container>
				</Section>
			</Page>
		</>
	);
}

function CenterContent(props: ParentProps) {
	return (
		<div class="relative z-0 mx-auto flex max-w-xl flex-col gap-4 py-16 text-center md:py-24">{props.children}</div>
	);
}

function BenefitCallout(props: ParentProps) {
	return <div class="col-span-3 flex flex-col items-center gap-6 text-center lg:col-span-1">{props.children}</div>;
}

const accessQuery = gql(`
query accessPage {
	facilities: facilitiesV3(supportsTroonAccess: true, sortBy: name) {
		facilities {
			...FacilityByState
		}
	}
	featured: facilitiesV3(limit: 2, featured: true, sortBy: name) {
		facilities {
			metadata {
	 			hero {
		 			url
		 		}
			}
		}
	}
}`);

const getData = cachedQuery(accessQuery);

function Review(props: ParentProps<{ rating: number }>) {
	return (
		<div class="flex flex-col gap-4 rounded border border-neutral p-6">
			<div>
				<Index each={[...Array(props.rating).keys()]}>
					{() => (
						<Icon
							name="star-filled"
							// eslint-disable-next-line tailwindcss/no-arbitrary-value
							class="text-[#FFBE5D]"
						/>
					)}
				</Index>
			</div>
			{props.children}
		</div>
	);
}

function Quote(props: ParentProps) {
	return (
		<blockquote
			// eslint-disable-next-line tailwindcss/no-arbitrary-value
			class="before:content-[open-quote] after:content-[close-quote]"
		>
			{props.children}
		</blockquote>
	);
}

function Cite(props: { children: string; location: string }) {
	return (
		<div class="flex w-full flex-row items-center gap-x-2">
			<Avatar
				class="size-10 text-neutral-600"
				firstName={props.children.split(' ')[0] ?? ''}
				lastName={props.children.split(' ')[1] ?? ''}
			/>
			<p class="flex flex-col gap-px">
				<span class="text-sm font-semibold">{props.children}</span>
				<span class="text-xs">{props.location}</span>
			</p>
		</div>
	);
}

const getBenefits = createContentfulRequest('cards');

export const route = { info: { hasHero: true }, preload: async () => getData({}) } satisfies RouteDefinition;

const golfpassLogo =
	'https://images.ctfassets.net/rdsy7xf5c8dt/39shrMUGcPa9lWDXsfZP7i/ed6d8f4d50f0f368a42722c1a9604bbc/golf-pass-plus-logo.png';
