import { Picture } from '@troon/ui';
import { getConfigValue } from '../modules/config';
import type { ParentProps } from 'solid-js';
import type { RouteDefinition } from '@solidjs/router';

export default function AuthPages(props: ParentProps) {
	return (
		<>
			<div class="relative -my-8 min-h-screen pb-24 md:-my-16">
				<Picture
					src={`https://${getConfigValue('MAP_HOST')}/assets/images/troon-north.jpg`}
					alt=""
					class="absolute inset-0 -z-10 w-screen self-stretch object-cover brightness-75"
					sizes={[
						[1200, 1200],
						[480, 960],
					]}
				/>
				<div class="mx-auto flex min-h-screen max-w-5xl grow items-center justify-center px-4 pt-24">
					{props.children}
				</div>
			</div>
		</>
	);
}

export const route = { info: { nav: 'minimal', hasHero: true } } satisfies RouteDefinition;
