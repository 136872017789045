import { For, Suspense, Show } from 'solid-js';
import {
	Heading,
	Container,
	Button,
	Link,
	Section,
	Picture,
	LinkButton,
	ContentCarousel,
	Page,
	TextLink,
} from '@troon/ui';
import { Title } from '@solidjs/meta';
import { NoHydration } from 'solid-js/web';
import { Video } from '@troon/video';
import { createAsync } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { getConfigValue } from '../../modules/config';
import { Hero } from '../../components/hero/photo';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { createContentfulRequest } from '../api/content/[model]/_client';
import { ContactForm } from './_components/contact-form';
import type { RouteDefinition } from '@solidjs/router';
import type { JSX } from 'solid-js';

export default function ManagementServicesPage() {
	const data = createAsync(() => getQuoteImages({ slugs: quotes.map((q) => q.courseSlug) }));

	const team = createAsync(
		async () => {
			const [execs, team] = await Promise.all([
				getPersonList('management-services-executives'),
				getPersonList('management-services-team'),
			]);
			return { execs: execs!, team: team! };
		},
		{ deferStream: true },
	);

	return (
		<>
			<Title>Golf club management services | Troon</Title>

			<NoHydration>
				<Hero src={`${getConfigValue('IMAGE_HOST')}/digital/hero/management-services.jpg`}>
					<div class="flex max-w-3xl flex-col gap-8 py-20 text-white">
						<Heading as="h1" class="text-center ">
							World-Class Golf and Club Management Services
						</Heading>
						<p class="text-center">
							Partner with the leading hospitality management company to elevate your club’s experience, achieve greater
							financial success, and drive operational excellence.
						</p>
						<Button type="button" as={Link} href="#contact" class="mx-auto size-fit">
							Get in touch <Icon name="arrow-right-md" />
						</Button>
					</div>
				</Hero>
			</NoHydration>

			<Page>
				<NoHydration>
					<Container>
						<Section>
							<div class="mx-auto flex max-w-xl flex-col items-center gap-4 text-center">
								<Heading as="h2" size="p" class="text-brand-600">
									Our Services
								</Heading>
								<p class="text-3xl font-semibold md:text-4xl">Tailored Solutions for Every Club’s Unique Needs</p>
							</div>
							<div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
								<For each={subpages}>
									{(data) => (
										<article class="group relative flex shrink grow basis-full flex-col gap-4">
											<div class="overflow-hidden rounded">
												<Picture
													src={data.image}
													sizes={[[400, 300]]}
													alt=""
													// eslint-disable-next-line tailwindcss/no-arbitrary-value
													class="pointer-events-none aspect-[4/3] w-full object-cover group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
												/>
											</div>
											<div class="flex flex-col gap-1">
												<Heading as="h3" size="h5">
													{data.title}
												</Heading>
												<p class="text-sm">{data.content}</p>
											</div>
											<LinkButton
												href={data.url}
												class="-ms-2 flex w-fit flex-row gap-2 after:absolute after:inset-0 after:z-0"
											>
												{data.linkText}
												<Icon name="chevron-right" />
											</LinkButton>
										</article>
									)}
								</For>
							</div>
						</Section>
					</Container>
				</NoHydration>

				<div>
					<Section appearance="primary">
						<Container class="flex flex-col gap-12">
							<NoHydration>
								<div class="grid grid-cols-1 gap-x-24 gap-y-4 lg:grid-cols-2">
									<Heading as="h2">Offering Unparalleled Multidisciplinary Expertise in Golf and Hospitality</Heading>
									<p>
										Troon is recognized as the world-wide leader in golf and club management services, operating more
										clubs than any other management company, with 900+ locations in 35+ countries spanning amenities
										such as golf, racquet sports, aquatics, fitness, food & beverage, lodging, and more.
									</p>
								</div>

								<ul class="grid grid-cols-1 gap-x-24 gap-y-12 text-lg font-semibold sm:grid-cols-3">
									<li class="flex flex-col gap-2 border-s-4 border-s-brand ps-8">
										<span class="text-5xl">925+</span>
										<span>Global locations</span>
									</li>
									<li class="flex flex-col gap-2 border-s-4 border-s-brand ps-8">
										<span class="text-5xl">80+</span>
										<span>Top 100 courses</span>
									</li>
									<li class="flex flex-col gap-2 border-s-4 border-s-brand ps-8">
										<span class="text-5xl">1,800+</span>
										<span>Racquet courts</span>
									</li>
								</ul>
							</NoHydration>

							<Video
								src={`${getConfigValue('CDN')}/digital/video/management-services/overview/index.m3u8`}
								poster={`${getConfigValue('IMAGE_HOST')}/digital/video/troon-approach-20240926.jpg`}
							/>
						</Container>
					</Section>

					<ContentCarousel controls="inline">
						<For each={quotes}>
							{(quote) => (
								<ContentCarousel.Item>
									<>
										<Suspense>
											<Show when={data()?.facilities}>
												<Picture
													src={
														data()?.facilities.facilities.find((f) => f.slug === quote.courseSlug)?.metadata?.hero?.url
													}
													alt=""
													sizes={[
														[480, 800],
														[1000, 400],
													]}
													crop="center"
													class="absolute inset-0 -z-0 size-full bg-center object-cover brightness-75"
													loading="lazy"
													draggable={false}
												/>
											</Show>
										</Suspense>
										<blockquote
											// eslint-disable-next-line tailwindcss/no-custom-classname
											class="quotes relative z-0 mx-auto flex h-full max-w-4xl flex-col items-center gap-8 px-4 py-36 text-center text-white"
										>
											<q class="text-2xl font-semibold">{quote.content}</q>
											<div class="flex flex-col gap-1">
												<span class="text-lg font-semibold">{quote.name}</span>
												<span>{quote.facility}</span>
											</div>
										</blockquote>
									</>
								</ContentCarousel.Item>
							)}
						</For>
					</ContentCarousel>
				</div>

				<NoHydration>
					<Section>
						<Container>
							<div class="grid grid-cols-1 gap-x-24 lg:grid-cols-2">
								<div>
									<Heading as="h2" size="p" class="text-brand-600">
										Our Team
									</Heading>
									<p class="max-w-lg text-3xl font-semibold md:text-4xl">Meet the Experts Behind Our Success</p>
								</div>
								<p>
									Troon’s Business Development team possesses a diverse background, decades of experience within the
									industry and company, as well as the ability to completely and concisely discuss with you the benefits
									of partnering with Troon. We look forward to talking with you and learning more about how we can help
									your club.
								</p>
							</div>
							<ul class="grid grid-cols-3 gap-x-4 gap-y-12 md:gap-x-8 lg:grid-cols-5">
								<For each={team()?.execs.fields.people}>
									{(person) => (
										<li class="flex flex-col gap-2">
											<Picture
												src={person?.fields.photo?.fields.file?.url}
												sizes={[[200, 280]]}
												alt={person?.fields.name}
												loading="lazy"
												class="w-full rounded"
												crop="face"
											/>
											<p class="flex flex-col gap-px">
												<span class="text-lg font-semibold">{person?.fields.name}</span>
												<span class="text-sm">{person?.fields.title}</span>
												<Show when={person?.fields.linkedin}>
													{(url) => (
														<TextLink href={url()}>
															<img src="/assets/images/social-linkedin.svg" alt="Linkedin logo" class="size-5" />
															<span class="sr-only">See {person?.fields.name} on Linkedin</span>
														</TextLink>
													)}
												</Show>
											</p>
										</li>
									)}
								</For>
							</ul>
						</Container>
					</Section>

					<Section>
						<Container>
							<Heading as="h3">Additional Team Members</Heading>
							<ul class="grid grid-cols-1 gap-x-8 gap-y-1 md:grid-cols-2 xl:grid-cols-3">
								<For each={team()?.team.fields.people}>
									{(person) => (
										<li class="inline-flex flex-wrap items-start gap-1">
											<Show
												when={person?.fields.linkedin}
												fallback={<span class="font-semibold">{person?.fields.name}</span>}
											>
												{(url) => (
													<Link href={url()} class="inline-flex flex-wrap items-center gap-1">
														<img
															src="/assets/images/social-linkedin.svg"
															alt="Linkedin logo"
															class="inline size-5"
															width={20}
															height={20}
														/>
														<span class="font-semibold">{person?.fields.name}</span>
													</Link>
												)}
											</Show>

											<span
												// eslint-disable-next-line tailwindcss/no-arbitrary-value
												class="text-sm before:content-['_—_']"
											>
												{person?.fields.title}
											</span>
										</li>
									)}
								</For>
							</ul>
						</Container>
					</Section>
				</NoHydration>

				<Section>
					<Container>
						<div
							id="contact"
							class="grid gap-24 rounded border border-neutral-400 bg-neutral-100 p-4 md:p-12 xl:grid-cols-2"
						>
							<ContactForm />
							<div class="flex items-start">
								<Picture
									src={`${getConfigValue('IMAGE_HOST')}/digital/about/headquarters.jpeg`}
									sizes={[[500, 600]]}
									alt=""
									class="hidden w-full rounded object-contain xl:block"
									crop="center"
								/>
							</div>
						</div>
					</Container>
				</Section>
			</Page>
		</>
	);
}

const query = gql(`
query managementServices($slugs: [String!]) {
	facilities: facilitiesV3(idOrSlugs: $slugs, sortBy: name) {
		facilities {
			slug
			metadata { hero { url } }
		}
	}
}`);

const getQuoteImages = cachedQuery(query);

export const route = { info: { hasHero: true } } satisfies RouteDefinition;

const subpages: Array<{ image: string; title: string; content: string; url: string; linkText: JSX.Element }> = [
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/private.jpg`,
		title: 'Private Clubs',
		content: 'Enhance your member experience to secure a sustainable future for the club.',
		url: '/management-services/private-club',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Private Club golf course management</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/daily-fee-and-resort.jpg`,
		title: 'Daily Fee & Resort',
		content: 'Enhance your guest experience and grow your club’s revenue.',
		url: '/management-services/daily-fee-and-resort',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Daily Fee Golf & Resort management</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/municipal.jpg`,
		title: 'Municipal Properties',
		content: 'Improve your course conditions and reduce your costs.',
		url: '/management-services/municipal',
		linkText: (
			<>
				Learn more<span class="sr-only"> about municipal golf course management</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/community-associations.jpg`,
		title: 'Community Associations',
		content: 'Integrate a hospitality-centric approach to your community.',
		url: '/management-services/community-associations',
		linkText: (
			<>
				Learn more<span class="sr-only"> about community association management</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/advisory-services.jpg`,
		title: 'Advisory Services',
		content: 'An alternative to professional management solutions.',
		url: '/management-services/advisory-services',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon’s advisory services</span>
			</>
		),
	},
	{
		image: `${getConfigValue('IMAGE_HOST')}/digital/hero/troon-partners-network-2024-11-04.jpg`,
		title: 'Troon Partners Network',
		content: 'Establish a brand identity among golf’s most influential audience.',
		url: '/management-services/troon-partners-network',
		linkText: (
			<>
				Learn more<span class="sr-only"> about Troon Partners Network</span>
			</>
		),
	},
];

const getPersonList = createContentfulRequest('person-list');

const quotes: Array<{ content: string; name: string; facility: string; courseSlug: string }> = [
	{
		content:
			'Having professionals who specialize in managing private clubs has added insights and opened avenues of information previously unavailable to the individuals on the Board of Directors, whose expertise lie in other areas. I’m pleased with the direction the Club is going and see a bright future in our association with Troon Privé.',
		name: 'Board President',
		facility: 'Alpine Country Club',
		courseSlug: 'alpine-country-club-ri',
	},
	{
		content:
			'We have truly enjoyed and appreciated our relationship with Troon as the manager of our five City golf courses. They have been a great partner for the City of Tucson.',
		name: 'Deputy Director',
		facility: 'City of Tucson Parks and Recreation Department',
		courseSlug: 'el-rio-golf-course',
	},
	{
		content:
			'Seattle Parks and Recreation is fortunate to have Troon as a partner. The Team at Troon is responsive, professional and always willing to do what it takes to improve the user experience. The communication is unmatched and the staff is more than helpful.',
		name: 'Park Resources & Construction Manager',
		facility: 'City of Seattle Parks and Recreation',
		courseSlug: 'jackson-park-golf-course-seattle',
	},
	{
		content:
			'The team at Troon made a lot of promises to the City of Phoenix during contract negotiations, and has delivered on each and every one of them. Course conditions improved, and our overall expense budget was greatly reduced. The City couldn’t be more pleased with its decision to hire this team.',
		name: 'Former Director of Parks',
		facility: 'City of Phoenix',
		courseSlug: 'papago-golf-club',
	},
	{
		content:
			'Working with Troon, working with the management that Troon has provided us, is actually a genuine pleasure.  They know what they are talking about, their experience is unparalleled, and we’ve made great progress in the last eight years.',
		name: 'Board President',
		facility: 'Marin Country Club',
		courseSlug: 'marin-country-club',
	},
	{
		content:
			'The decision to hire Troon was a pivotal moment within Belfair’s storied history.  The vibrancy of the community would not be what it is without the leadership of our General Manager with the support of Troon.',
		name: 'Board Member',
		facility: 'Belfair',
		courseSlug: 'belfair',
	},
];
