import { PlainDocument, preload } from '../../layouts/plain-document';
import type { RouteDefinition } from '@solidjs/router';

export default function RewardsDocPage() {
	return (
		<PlainDocument
			breadcrumbs={[{ href: '/access', title: 'Troon Access' }]}
			slug="troon-access-terms-and-conditions"
		/>
	);
}

export const route = { preload: preload('troon-access-terms-and-conditions') } satisfies RouteDefinition;
