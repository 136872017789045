import { Meta, Title } from '@solidjs/meta';
import { Heading, Picture, Container, BreadcrumbItem, Page, Section } from '@troon/ui';
import { For } from 'solid-js';
import { NoHydration } from 'solid-js/web';
import { createAsync, useLocation } from '@solidjs/router';
import { getConfigValue } from '../../modules/config';
import { Hero } from '../../components/hero/photo';
import { createContentfulRequest } from '../api/content/[model]/_client';
import type { RouteDefinition } from '@solidjs/router';

export default function SeniorExecutivesPage() {
	const loc = useLocation();

	const team = createAsync(
		async () => {
			const [execs, officers] = await Promise.all([getPersonList('executive-team'), getPersonList('officers')]);
			return { execs: execs!, officers: officers! };
		},
		{ deferStream: true },
	);

	return (
		<NoHydration>
			<Title>Senior Executives | Troon</Title>
			<Meta
				name="description"
				content="The senior executives and leadership team Troon and Troon's affiliated brands."
			/>

			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/digital/about/headquarters.jpeg`}
				crop="center"
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/about">About Troon</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>Senior Executives</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1" class="text-center text-white">
					<span class="sr-only">Troon </span>Senior Executives
				</Heading>
			</Hero>

			<Page>
				<Container>
					<Section>
						<ul class="grid grid-cols-3 gap-x-4 gap-y-12 sm:grid-cols-4 md:gap-x-8 lg:grid-cols-6">
							<For each={team()?.execs.fields.people}>
								{(person) => (
									<li class="flex flex-col gap-2">
										<Picture
											src={person?.fields.photo?.fields.file?.url}
											sizes={[[200, 280]]}
											alt={person?.fields.name}
											loading="lazy"
											class="w-full rounded"
											crop="face"
										/>
										<p class="flex flex-col gap-px">
											<span class="text-lg font-semibold">{person?.fields.name}</span>
											<span class="text-sm">{person?.fields.title}</span>
										</p>
									</li>
								)}
							</For>
						</ul>
					</Section>
				</Container>

				<Section appearance="secondary">
					<Container class="flex flex-col gap-8">
						<Heading as="h2">Other officers</Heading>
						<ul class="grid grid-cols-2 gap-x-8 gap-y-1">
							<For each={team()?.officers.fields.people}>
								{(person) => (
									<li>
										<span class="font-semibold">{person?.fields.name}</span>
										<span
											// eslint-disable-next-line tailwindcss/no-arbitrary-value
											class="text-sm before:content-['_—_']"
										>
											{person?.fields.title}
										</span>
									</li>
								)}
							</For>
						</ul>
					</Container>
				</Section>
			</Page>
		</NoHydration>
	);
}

const getPersonList = createContentfulRequest('person-list');

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
