import { Navigate, useSearchParams } from '@solidjs/router';
import { Match, Switch } from 'solid-js';
import { Container, Page } from '@troon/ui';
import ConfirmSubscription from '../../../../components/confirm-subscription';
import type { RouteDefinition } from '@solidjs/router';

export default function ConfirmationPage() {
	const [params] = useSearchParams<{ subscriptionId?: string; productId?: string }>();

	return (
		<Switch>
			<Match when={!params.subscriptionId || !params.productId}>
				<Navigate href="/access" />
			</Match>
			<Match when>
				<Page>
					<Container size="xsmall">
						<ConfirmSubscription subscriptionId={params.subscriptionId!} productId={params.productId!} />
					</Container>
				</Page>
			</Match>
		</Switch>
	);
}

export const route = { info: { nav: 'minimal' } } satisfies RouteDefinition;
